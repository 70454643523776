import $ from 'cash-dom';
import anime from 'animejs';

const $page = $('html, body');
const $loader = $('.loader');
const bar = $loader.find('.progress .progress-bar').get(0);
const $images = $('img');
const dependencies = $images.length;
let resolver;
let loaded = 0;
/** @type {anime.AnimeInstance} */
let animation = null;

function animateLoader() {
  if (animation) {
    animation.pause();
    animation = null;
  }
  animation = anime({
    targets: bar,
    width: `${loaded / dependencies * 100}%`,
    easing: 'easeOutQuart',
    duration: 300,
    complete() {
      if (loaded === dependencies) {
        anime({
          targets: $loader.get(0),
          opacity: 0,
          easing: 'easeOutQuart',
          duration: 500,
          complete() {
            $loader.hide();
            $page.css({ overflow: '' });
            resolver();
          },
        });
      }
    },
  });
}

export default function initialize() {
  return new Promise((resolve) => {
    resolver = resolve;
    $page.css({ overflow: 'hidden' });
    $images.each((_index, image) => {
      // avoids caching issues
      const img = new Image();
      img.addEventListener('load', () => {
        loaded += 1;
        animateLoader();
      });
      img.src = image.src;
    });
  });
}
