import $ from 'cash-dom';
import anime from 'animejs';

const $page = $('html, body');
const $modal = $('.modal');
const $videoLink = $('.video-link');
let player;

export default function initialize() {
  player = new YT.Player($('.video-player').get(0), {
    videoId: 'JcUfqCCAhok',
    playerVars: {
      theme: 'light',
      modesbranding: 0,
      color: 'white',
      iv_load_policy: 3,
      showinfo: 1,
      controls: 1,
    },
  });
  player.addEventListener('onStateChange', (event) => {
    if (event.data === YT.PlayerState.ENDED && $modal.css('display') === 'block') {
      $modal.trigger('click');
    }
  });

  $videoLink.on('click', (event) => {
    event.preventDefault();
    $page.css({ overflow: 'hidden' });

    $modal.show();
    anime({
      targets: $modal.get(0),
      opacity: 1,
      duration: 250,
      easing: 'easeOutQuart',
      complete() {
        player.playVideo();
      },
    });
  });

  $modal.on('click', () => {
    anime({
      targets: $modal.get(0),
      opacity: 0,
      duration: 250,
      easing: 'easeOutQuart',
      complete() {
        $modal.hide();
        $page.css({ overflow: '' });
        player.stopVideo();
      },
    });
  });
}
