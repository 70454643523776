import 'destyle.css/destyle.css';
import '../styles/style.scss';

import $ from 'cash-dom';
import progressBar from './progress-bar';
import backgroundSlider from './background-slider';
import modal from './modal';

$(() => {
  progressBar()
    .then(() => {
      backgroundSlider();
    });
});

window.onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
  modal();
};
