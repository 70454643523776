import $ from 'cash-dom';
import { shuffle } from 'lodash';

const $typewriter = $('.subtitle .typewriter');
let texts = shuffle([
  'something to offer',
  'something to teach',
  'a special skill',
  'a useful insight',
  'a sense of purpose',
  'a superpower',
]);

const keyInterval = 20;
let next = 0;
let iteration = 0;
let typeInterval = +new Date();

export function writeNext() {
  if (+new Date() - typeInterval >= keyInterval) {
    if (iteration === 0) {
      $typewriter.empty();
    }
    iteration += 1;
    typeInterval = +new Date();
    const text = texts[next];
    const part = text.substr(0, iteration);
    $typewriter.text(part);
    if (part !== text) {
      requestAnimationFrame(writeNext);
    } else {
      next += 1;
      if (next === texts.length) {
        texts = shuffle(texts);
        next = 0;
      }
      iteration = 0;
    }
  } else {
    requestAnimationFrame(writeNext);
  }
}

export function deleteAll() {
  if (+new Date() - typeInterval >= keyInterval) {
    typeInterval = +new Date();
    const text = $typewriter.text().trim();
    $typewriter.text(text.substr(0, text.length - 1));
    if ($typewriter.text().length > 0) {
      requestAnimationFrame(deleteAll);
    } else {
      $typewriter.html('&nbsp;');
    }
  } else {
    requestAnimationFrame(deleteAll);
  }
}
