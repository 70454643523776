import $ from 'cash-dom';
import anime from 'animejs';
import { deleteAll, writeNext } from './subtitle-typewriter';
import intro from './intro';

const $images = $('.background-slider img');
const transitionDuration = 1000;
const delayDuration = 5000;
let frameTimer = -1;
let current = $images.length - 1;
let introInitialized = false;

function rollNext() {
  const $last = $($images.get(current));
  $last.css({ zIndex: 0 });

  current += 1;
  if (current === $images.length) {
    current = 0;
  }

  $($images.get(current)).css({ zIndex: 1 });

  anime({
    targets: $images.get(current),
    opacity: 1,
    duration: transitionDuration,
    easing: 'easeInOutCubic',
    complete() {
      $last.css({ opacity: 0 });
      if (!introInitialized) {
        intro();
        introInitialized = true;
      }
      writeNext();
    },
    begin() {
      deleteAll();
    },
  });
}

// eslint-disable-next-line consistent-return
export default function initialize() {
  if (frameTimer > -1) {
    if (+new Date() - frameTimer < delayDuration) {
      return requestAnimationFrame(initialize);
    }
  }
  rollNext();
  frameTimer = +new Date();
  requestAnimationFrame(initialize);
}
