import $ from 'cash-dom';
import anime from 'animejs';

export default function initialize() {
  const targets = [
    document.querySelector('.title'),
    document.querySelector('.lead'),
    document.querySelector('.email-link'),
    document.querySelector('.video-link'),
  ];
  anime({
    targets,
    easing: 'easeOutSine',
    duration: 250,
    translateX: [50, 0],
    opacity: 1,
    delay: anime.stagger(350, { start: 200 }),
    complete() {
      $(targets[2]).addClass('animated').css({ opacity: '' });
    },
  });
}
